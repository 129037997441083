import styled from 'styled-components'
import { theme } from './theme/theme'

export const Layout = styled.div`
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
    max-width: 100%;
    padding: 48px ${theme.space.md} 0;
    background: ${theme.colors.blueLight};
    
    ${theme.mediaQueries.lg} {
        max-width: 1024px;
        margin: 0 auto;
        padding: 48px 0 0 0;
    }
`

export const CenteredContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: ${theme.space.lg};
`

export const FixedFooterContainer = styled.div`
    padding-bottom: 90px;
`
