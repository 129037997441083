export const xs = 12 // 0.75rem
export const sm = 14 // 0.875rem
export const md = 16 // 1rem H6
export const lg = 18 // 1.125rem / H5
export const xl = 22 // 1.375rem / H4
export const xxl = 26 // 1.625 / H3
export const xxxl = 32 // 2rem / H2
export const xxxxl = 38 // 2.375rem / H1
export const xxxxxl = 52 // 3.25rem

export const fonts = {
  default: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
}

export function convertToRem(fontSize: number) {
  return `${fontSize / md}rem`
}

export const fontSizes = {
  xs: convertToRem(xs),
  sm: convertToRem(sm),
  md: convertToRem(md),
  lg: convertToRem(lg),
  xl: convertToRem(xl),
  xxl: convertToRem(xxl),
  xxxl: convertToRem(xxxl),
  xxxxl: convertToRem(xxxxl),
  xxxxxl: convertToRem(xxxxxl),
}

export type FontSize = keyof typeof fontSizes
