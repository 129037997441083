import React, { useContext, useState } from 'react'
import Button from '@mui/material/Button'
import Alert from '@mui/material/Alert'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { useHistory } from 'react-router-dom'
import * as ls from 'local-storage'
import { API } from '../providers/API'
import { InputField } from '../components/FormElements/InputField'
import { CenteredContainer } from '../App.styles'
import { GlobalStateContext } from '../providers/GlobalStateProvider'
import { theme } from '../theme/theme'
import logo from '../media/ubs-logo.jpeg'

const ValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email()
    .required('Моля въведете email'),
  password: Yup.string().required('Моля въведете паролата си'),
})

export const LoginPage = () => {
  const [loginFailure, setLoginFailure] = useState(false)
  const { setPageTitle, setUserAccess, setUserInfo } = useContext(GlobalStateContext)
  setPageTitle('Вход')
  const history = useHistory()

  return (
    <CenteredContainer>
      <img src={logo} alt="UBS Trade" />
      <h1>Вход</h1>
      <Formik
        initialValues={{ email: '', password: '' }}
        validationSchema={ValidationSchema}
        enableReinitialize
        onSubmit={(values) => {
          API.get('/csrf-cookie').then(() => {
            API.post('/login',
              { email: values.email, password: values.password }).then((response) => {
              if (response.data.token) {
                ls.set('loginCredentials', response.data.token)
                ls.set('userAccess', response.data.user.access)
                setUserAccess(response.data.user.access)
                setUserInfo(response.data.user)
                ls.set('userInfo', response.data.user)
                history.push('/machines')
              }
            }).catch(() => {
              setLoginFailure(true)
            })
          })
        }}
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <InputField label="Email" name="email" id="email" type="text" />
            <InputField label="Парола" name="password" id="password" type="password" />
            <Button
              variant="contained"
              type="submit"
              color="primary"
              fullWidth
            >
              Вход
            </Button>
            {loginFailure && <Alert severity="error" onClose={() => setLoginFailure(false)} style={{ marginTop: theme.space.md }}>Невярен имейл или парола</Alert>}
          </form>
        )}
      </Formik>
    </CenteredContainer>
  )
}
