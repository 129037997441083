import React, { useContext, useEffect, useState } from 'react'
import * as ls from 'local-storage'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import Button from '@mui/material/Button'
import Alert from '@mui/material/Alert'
import { GlobalStateContext } from '../../providers/GlobalStateProvider'
import { ApprovalBoxContainer } from './ApprovalBox.styles'
import { ApprovalBoxProps } from './ApprovalBox.types'
import { ApprovalBoxToggle } from './ApprovalBoxToggle'
import { SignatureComponent } from '../SignaturePad/SignaturePad'
import { API } from '../../providers/API'
import { theme } from '../../theme/theme'

interface userSelectProps {
  id: string
}

export const ApprovalBox: React.FC<ApprovalBoxProps> = ({ selectedBranch, service = false }) => {
  const [usersListState, setUsersListState] = useState([])
  const [allUsers, setAllUsers] = useState([])
  const [selectedUser, setSelectedUser] = useState<string>()
  const [signatureData, setSignatureData] = useState()
  const [submitSuccess, setSubmitSuccess] = useState(false)
  const [submitFailure, setSubmitFailure] = useState(false)
  const [submitDeclinedSuccess, setSubmitDeclinedSuccess] = useState(false)
  const [submitDeclinedFailure, setSubmitDeclinedFailure] = useState(false)
  const {
    userAccess,
    machineServiceData,
    setMachineServiceData,
    machineDeclinedServiceData,
    approvalBoxOpen,
    setApprovalBoxOpen,
    userInfo,
  } = useContext(GlobalStateContext)

  useEffect(() => {
    // TODO bankid ?
    API.get('/listBankUsers/1', { headers: { Authorization: `Bearer ${ls.get('loginCredentials')}` } }).then((response) => {
      const usersList:any = []
      response.data.forEach((item:any) => {
        usersList.push({ label: item.name, id: item.id, branchId: parseInt(item.branchId) })
      })

      setAllUsers(usersList)
      setUsersListState(usersList)
    })
  }, [])

  useEffect(() => {
    const filteredUsersList = allUsers.filter((item:any) => item.branchId === selectedBranch)

    setUsersListState(filteredUsersList)
  }, [selectedBranch])

  const submitEvent = () => {
    const sigData = JSON.stringify(signatureData)
    const serviceAction = userAccess === 3 ? 'одобренСервиз' : 'сервиз'

    const eventData: object = {
      eventType: service ? serviceAction : 'посещение',
      userId: service ? userInfo.id : selectedUser?.toString(),
      sigData,
      ...machineServiceData,
    }

    API.post('/event', eventData, { headers: { Authorization: `Bearer ${ls.get('loginCredentials')}` } }).then(() => {
      setSubmitSuccess(true)
      setMachineServiceData({
        machineData: [],
      })
      setTimeout(() => {
        setSubmitSuccess(false)
        setApprovalBoxOpen(false)
      }, 3000)
    }).catch(() => {
      setSubmitFailure(true)
    })

    if (machineDeclinedServiceData.machineData.length > 0) {
      const declinedEventData = {
        eventType: 'отказанСервиз',
        userId: service ? userInfo.id : selectedUser?.toString(),
        sigData,
        ...machineDeclinedServiceData,
      }

      API.post('/event', declinedEventData, { headers: { Authorization: `Bearer ${ls.get('loginCredentials')}` } }).then(() => {
        setSubmitDeclinedSuccess(true)
        setTimeout(() => {
          setSubmitDeclinedSuccess(false)
          setApprovalBoxOpen(false)
        }, 3000)
      }).catch(() => {
        setSubmitDeclinedFailure(true)
      })
    }
  }

  return (
    <ApprovalBoxContainer open={approvalBoxOpen}>
      <ApprovalBoxToggle />
      {!service && (
        <Autocomplete
          disablePortal
          options={usersListState}
          renderInput={(params) => <TextField {...params} label="Служител" />}
          fullWidth
          onChange={(event, newValue: userSelectProps | null) => {
            setSelectedUser(newValue!.id)
          }}
        />
      )}
      <SignatureComponent setSignatureDataFn={setSignatureData} />
      <Button
        variant="contained"
        type="button"
        color="primary"
        fullWidth
        onClick={() => submitEvent()}
      >
        Запази
      </Button>
      {submitSuccess && <Alert severity="success" style={{ marginTop: theme.space.md }}>Данните са записани успешно</Alert>}
      {submitFailure && <Alert severity="error" onClose={() => setSubmitFailure(false)} style={{ marginTop: theme.space.md }}>Възникна грешка при записването на данните</Alert>}
      {submitDeclinedSuccess && <Alert severity="success" style={{ marginTop: theme.space.md }}>Бракуваните машини са записани успешно</Alert>}
      {submitDeclinedFailure && <Alert severity="error" onClose={() => setSubmitFailure(false)} style={{ marginTop: theme.space.md }}>Възникна грешка при записването на бракуваните машини</Alert>}
    </ApprovalBoxContainer>
  )
}
