import React, { useContext, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import * as ls from 'local-storage'
import { API } from '../../providers/API'
import { GlobalStateContext } from '../../providers/GlobalStateProvider'
import { SlideoutCloseButton, StyledMenu } from './Slideout.styles'

export const useOnClickOutside = (ref: any, handler: any) => {
  useEffect(() => {
    const listener = (event: any) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return
      }
      handler(event)
    }
    document.addEventListener('mousedown', listener)
    return () => {
      document.removeEventListener('mousedown', listener)
    }
  },
  [ref, handler])
}

export const Slideout: React.FC = () => {
  const {
    slideoutOpen, setSlideoutOpen, userAccess, setUserAccess, setUserInfo,
  } = useContext(GlobalStateContext)

  const node = useRef(null)
  useOnClickOutside(node, () => setSlideoutOpen(false))

  return (
    <StyledMenu open={slideoutOpen} ref={node}>
      <SlideoutCloseButton
        role="button"
        tabIndex={0}
        onClick={() => {
          setSlideoutOpen(false)
        }}
        onKeyUp={() => {
          setSlideoutOpen(false)
        }}
      >
        <div />
        <div />
      </SlideoutCloseButton>
      {userAccess !== 0 ? (
        <ul>
          <li>
            <Link
              onClick={() => setSlideoutOpen(false)}
              to="/machines"
            >
              Машини
            </Link>

          </li>
          <li>
            <Link
              onClick={() => setSlideoutOpen(false)}
              to="/service"
            >
              Сервиз
            </Link>

          </li>
          {userAccess <= 2 && (
            <>
              <li>
                <Link
                  onClick={() => setSlideoutOpen(false)}
                  to="/storage"
                >
                  Склад
                </Link>

              </li>
              <li>
                <Link
                  onClick={() => setSlideoutOpen(false)}
                  to="/createBranch"
                >
                  Нов клон
                </Link>

              </li>
              <li>
                <Link
                  onClick={() => setSlideoutOpen(false)}
                  to="/createMachine"
                >
                  Нова машина
                </Link>
              </li>
            </>
          )}
          <li>
            <Link
              to="/login"
              onClick={() => {
                API.post('/logout', {}, { headers: { Authorization: `Bearer ${ls.get('loginCredentials')}` } }).then(() => {
                  setUserAccess(0)
                  ls.set('userAccess', 0)
                  ls.set('loginCredentials', '')
                  ls.set('userInfo', '')
                  setUserInfo({})
                  setSlideoutOpen(false)
                })
              }}
            >
              Изход
            </Link>
          </li>
        </ul>
      ) : (
        <ul>
          <li>
            <Link
              onClick={() => setSlideoutOpen(false)}
              to="/login"
            >
              Вход
            </Link>

          </li>
          <li>
            <Link
              onClick={() => setSlideoutOpen(false)}
              to="/register"
            >
              Регистрация
            </Link>

          </li>
        </ul>
      )}
    </StyledMenu>
  )
}
