import styled, { css } from 'styled-components'
import { theme } from '../../theme/theme'

export const ApprovalBoxContainer = styled.div<{open: boolean}>(
  ({ open }) => css`
  position: fixed;
  z-index: 100;
  width: 100%;
  bottom: 0;
  left: 0;
  padding: ${theme.space.md};
  box-sizing: border-box;
  background: #FFF;
  box-shadow: 0px 0px 20px -5px #000000;
  transition: transform 0.3s ease-in-out;
  // 68px is the height of a button + 2 MD margins
  transform: ${open ? 'translateY(0)' : 'translateY(calc(100% - 68px))'};
`,
)
