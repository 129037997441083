import styled from 'styled-components'
import { theme } from '../../theme/theme'

export const SuccessNotification = styled.div`
    margin-top: ${theme.space.md};
    padding: ${theme.space.md};
    color: #3c763d;
    background-color: #dff0d8;
    border-color: #d6e9c6;
    font-weight: bold;
    border-radius: 5px;
`
