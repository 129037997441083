import { createGlobalStyle } from 'styled-components'
import { theme } from './theme'

export const GlobalStyle = createGlobalStyle`
    html {
        font-family: ${theme.fonts.default};
        text-size-adjust: 100%;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        box-sizing: border-box;
    }

    body, html {
        padding: 0;
        margin: 0;
        background: ${theme.colors.blueLight};
    }

    *,
    *:before,
    *:after {
        box-sizing: inherit;
    }

    img {
        max-width: 100%;
        height: auto;
    }
`
