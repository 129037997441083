import React from 'react'
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { createTheme, ThemeProvider as MaterialThemeProvider } from '@mui/material/styles'
import * as ls from 'local-storage'
import { GlobalStateProvider } from './providers/GlobalStateProvider'
import { GlobalStyle } from './theme/global.styles'
import { theme } from './theme/theme'
import { ProtectedRoute } from './components/ProtectedRoute/ProtectedRoute'

import { Layout } from './App.styles'
import { Header } from './components/Header/Header'
import { Slideout } from './components/Slideout/Slideout'
import { LoginPage } from './pages/Login'
import { ReferencePage } from './pages/ReferencePage'
import { MachinesInStorage } from './pages/MachinesInStorage'
import { Machines } from './pages/Machines'
import { Service } from './pages/Service'
import { CreateMachine } from './pages/CreateMachine'
import { CreateBranch } from './pages/CreateBranch'
import { RegisterPage } from './pages/Register'

const MUItheme = createTheme({
  palette: {
    primary: {
      main: theme.colors.grayDark,
    },
    secondary: {
      main: theme.colors.greyLight,
    },
  },

  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          marginBottom: theme.space.md,

        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          background: '#FFF',
        },
      },
    },
    MuiPagination: {
      styleOverrides: {
        ul: {
          justifyContent: 'center',
        },
        root: {
          margin: `0 -${theme.space.md} ${theme.space.md}`,
        },
      },
    },
  },
})

export const App = () => (
  <GlobalStateProvider>
    <ThemeProvider theme={theme}>
      <MaterialThemeProvider theme={MUItheme}>
        <GlobalStyle />
        <Layout>
          <Router>
            <Header />
            <Slideout />
            <Switch>
              <Route path="/" exact component={ls.get('userInfo') === null || ls.get('userInfo') === '' ? LoginPage : Machines} />
              <Route path="/login" exact component={LoginPage} />
              <Route path="/reference" exact component={ReferencePage} />
              <Route
                path="/register"
                exact
                component={RegisterPage}
              />
              <ProtectedRoute
                path="/storage"
                accessLevel={2}
                component={MachinesInStorage}
              />
              <ProtectedRoute
                path="/machines"
                accessLevel={3}
                component={Machines}
              />
              <ProtectedRoute
                path="/service"
                accessLevel={3}
                component={Service}
              />
              <ProtectedRoute
                path="/createMachine"
                accessLevel={2}
                component={CreateMachine}
              />
              <ProtectedRoute
                path="/editMachine/:machineId"
                accessLevel={2}
                component={CreateMachine}
              />
              <ProtectedRoute
                path="/createBranch"
                accessLevel={2}
                component={CreateBranch}
              />
            </Switch>
          </Router>
        </Layout>
      </MaterialThemeProvider>
    </ThemeProvider>
  </GlobalStateProvider>
)
