import styled from 'styled-components'
import { theme } from '../../theme/theme'

export const TopBar = styled.div`
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  background: ${theme.colors.blueDark};
  color: #FFF;
  padding: ${theme.space.sm} ${theme.space.md};
  box-shadow: 0px 0px 15px 0px #000000;
`

export const NavigationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const TitleContainer = styled.div`
  display: flex;
  align-items: left;
  justify-content: space-between;
  padding: ${theme.space.md} ${theme.space.md};
  margin: 0 -${theme.space.md} ${theme.space.lg};

  & > div:last-child {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  & > div:first-child {
    flex-grow: 0;
    margin: 0 10px 0 0;
  }

  h1 {
    margin: 0;
    font-size: 24px;
  }

  img {
    width: 130px;
  }
`

export const StyledBurger = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  
  &:focus {
    outline: none;
  }
  
  div {
    width: 2rem;
    height: 0.25rem;
    background: #FFF;
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;
  }
`
