import styled, { css } from 'styled-components'
import { theme } from '../../theme/theme'

interface StyledMenuProps {
  open: boolean
}

export const StyledMenu = styled.nav<StyledMenuProps>(
  ({ open }) => css`
  display: flex;
  flex-direction: column;
  background: ${theme.colors.blueDark};
  height: 100vh;
  width: 200px;
  text-align: left;
  padding: 0 ${theme.space.md} ${theme.space.md};
  position: fixed;
  z-index: 101;
  top: 0;
  left: 0;
  transition: transform 0.3s ease-in-out;
  transform: ${open ? 'translateX(0)' : 'translateX(-100%)'};
  
  button {
    appearance: none;
    background: none;
    border: none;
    width: 40px;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li {
    margin: 0;
  }

  a {
    display: block;
    border-bottom: 3px solid ${theme.colors.blueLight};
    padding: ${theme.space.sm};
    font-size: 18px;
    color: #FFF;
    text-decoration: none;
  }
    `,
)

export const SlideoutCloseButton = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  position: relative;
  margin-top: ${theme.space.sm};

  div {
    width: 2rem;
    height: 0.25rem;
    background: #FFF;
    border-radius: 10px;
    transition: all 0.3s linear;
    position: absolute;
    transform-origin: center;
  }
  
  & > div:first-child {
    transform: rotate(45deg)
  }
  
  & > div:last-child {
    transform: rotate(135deg)
  }
`
