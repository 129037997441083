import React, { useContext, useEffect } from 'react'
import SignaturePad from 'react-signature-canvas'
import Button from '@mui/material/Button'
import { GlobalStateContext } from '../../providers/GlobalStateProvider'
import { md as SpaceMd } from '../../theme/space'
import { theme } from '../../theme/theme'

export const SignatureComponent: React.FC<{setSignatureDataFn: any}> = ({ setSignatureDataFn }) => {
  const { approvalBoxOpen } = useContext(GlobalStateContext)
  const sigPad = React.useRef() as React.MutableRefObject<any>
  const canvasWidth = window.innerWidth - SpaceMd - SpaceMd

  useEffect(() => {
    if (approvalBoxOpen === false) {
      sigPad.current.clear()
    }
  }, [approvalBoxOpen])

  return (
    <>

      <SignaturePad
        backgroundColor="#FFF9C5"
        canvasProps={{ width: canvasWidth, height: 150 }}
        ref={sigPad}
        onEnd={() => setSignatureDataFn(sigPad.current.toData())}
      />
      <Button
        variant="contained"
        type="button"
        color="secondary"
        fullWidth
        onClick={() => sigPad.current.clear()}
        style={{ marginBottom: theme.space.md }}
      >
        Изчисти подпис
      </Button>

    </>
  )
}
