import React, { useContext, useEffect, useState } from 'react'
import * as ls from 'local-storage'
import { Formik } from 'formik'
import * as Yup from 'yup'
import Button from '@mui/material/Button'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import { GlobalStateContext } from '../providers/GlobalStateProvider'
import { API } from '../providers/API'
import { bankProps } from '../components/Machine/Machine.types'
import { SuccessNotification } from '../components/SuccessNotification/SuccessNotification.styles'

const ValidationSchema = Yup.object().shape({
  bankName: Yup.string().required('Въведете банка'),
  City: Yup.string().required('Въведете град'),
  branchName: Yup.string().required('Въведете име на клон'),
})

export const CreateBranch = () => {
  const { setPageTitle } = useContext(GlobalStateContext)
  setPageTitle('Нов Клон')

  const [bankListState, setBankListState] = useState<bankProps[]>([])
  const [showSuccess, setShowSuccess] = useState<boolean>(false)

  useEffect(() => {
    API.get('/bank', { headers: { Authorization: `Bearer ${ls.get('loginCredentials')}` } }).then((response) => {
      const banksList:any = []
      response.data.forEach((item:any) => {
        banksList.push({ label: item.bankName })
      })
      setBankListState(banksList)
    })
  }, [])

  return (
    <Formik
      initialValues={{
        bankName: '',
        City: '',
        branchName: '',
      }}
      validationSchema={ValidationSchema}
      enableReinitialize
      onSubmit={(values, actions) => {
        API.post('/branch', values, { headers: { Authorization: `Bearer ${ls.get('loginCredentials')}` } }).then(() => {
          actions.resetForm()
          setShowSuccess(true)
          setTimeout(() => setShowSuccess(false), 3000)
        })
      }}
    >
      {({
        handleSubmit, setFieldValue, values, errors,
      }) => (
        <form onSubmit={handleSubmit}>
          <Autocomplete
            disablePortal
            // @ts-ignore-next-line
            value={values.bankName}
            isOptionEqualToValue={(option, value) => option.label === value.label}
            options={bankListState}
            sx={{ width: 300 }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Банка"
                name="bankName"
                error={!!errors.bankName}
                helperText={errors.bankName}
              />
            )}
            onChange={(event, newValue) => {
              setFieldValue('bankName', newValue!.label)
            }}
          />
          <Autocomplete
            disablePortal
            value={values.City}
            options={['Пловдив', 'София', 'Русе', 'Варна']}
            sx={{ width: 300 }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Град"
                name="City"
                error={!!errors.City}
                helperText={errors.City}
              />
            )}
            onChange={(event, newValue) => {
              setFieldValue('City', newValue)
            }}
          />
          <TextField
            label="Име на клон"
            name="branchName"
            value={values.branchName}
            error={!!errors.branchName}
            helperText={errors.branchName}
            onChange={(e) => {
              setFieldValue('branchName', e.target.value)
            }}
          />

          <Button
            variant="contained"
            type="submit"
            color="primary"
            fullWidth
          >
            Създай клон
          </Button>
          {showSuccess && <SuccessNotification>Данните са записани успешно!</SuccessNotification>}
        </form>
      )}
    </Formik>
  )
}
