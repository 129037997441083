import React, {
  Dispatch, useEffect, useState, SetStateAction,
} from 'react'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import * as ls from 'local-storage'
import { MachineFiltersHolder } from './MachineFilters.styles'
import { API } from '../../providers/API'

interface MachineFiltersProps {
  setMachinesFunction: Dispatch<SetStateAction<never[]>>,
}

export const MachineFiltersInStorage: React.FC<MachineFiltersProps> = ({ setMachinesFunction }) => {
  const [storageListPerm, setStorageListPerm] = useState([])

  useEffect(() => {
    API.get('/storage', { headers: { Authorization: `Bearer ${ls.get('loginCredentials')}` } }).then((response) => {
      const storageList:any = []
      response.data.forEach((item:any) => {
        storageList.push({ label: item.location, value: item.id })
      })
      setStorageListPerm(storageList)
    })
  }, [])

  const getMachinesInStorage = (value: any) => {
    API.get(`/storage/listStorage/${value.value}`, { headers: { Authorization: `Bearer ${ls.get('loginCredentials')}` } }).then((response) => {
      setMachinesFunction(response.data)
    })
  }

  return (
    <MachineFiltersHolder>
      <Autocomplete
        disablePortal
        options={storageListPerm}
        sx={{ width: 300 }}
        renderInput={(params) => <TextField {...params} label="Склад" />}
        onChange={(event, newValue) => {
          getMachinesInStorage(newValue)
        }}
      />
    </MachineFiltersHolder>
  )
}
