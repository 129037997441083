import React, { useContext } from 'react'
import Button from '@mui/material/Button'
import { useHistory } from 'react-router-dom'
import { Formik } from 'formik'
import * as Yup from 'yup'
import * as ls from 'local-storage'
import { API } from '../providers/API'
import { InputField } from '../components/FormElements/InputField'
import { GlobalStateContext } from '../providers/GlobalStateProvider'
import { CenteredContainer } from '../App.styles'
import logo from '../media/ubs-logo.jpeg'

const ValidationSchema = Yup.object().shape({
  name: Yup.string().required('Моля въведете име'),
  email: Yup.string()
    .email()
    .required('Моля въведете email'),
  password: Yup.string().required('Моля въведете паролата си'),
  password_confirmation: Yup.string().required('Моля потвърдете паролата си').oneOf([Yup.ref('password')], 'Паролите трябва да съвпадат'),
})

export const RegisterPage = () => {
  const { setPageTitle, setUserAccess } = useContext(GlobalStateContext)
  setPageTitle('Регистрация')
  const history = useHistory()

  return (
    <CenteredContainer>
      <img src={logo} alt="UBS Trade" />
      <h1>Регистрация</h1>
      <Formik
        initialValues={{
          name: '', email: '', password: '', password_confirmation: '',
        }}
        validationSchema={ValidationSchema}
        enableReinitialize
        onSubmit={(values) => {
          API.post('/register', values).then((response) => {
            if (response.data.token) {
              ls.set('loginCredentials', response.data.token)
              ls.set('userAccess', 9)
              setUserAccess(9)
              history.push('/machines')
            }
          })
        }}
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <InputField label="Име" name="name" id="name" type="text" />
            <InputField label="Email" name="email" id="email" type="text" />
            <InputField label="Парола" name="password" id="password" type="password" />
            <InputField label="Потвърди парола" name="password_confirmation" id="password_confirmation" type="password" />
            <Button
              variant="contained"
              type="submit"
              color="primary"
              fullWidth
            >
              Регистрация
            </Button>

          </form>
        )}
      </Formik>
    </CenteredContainer>
  )
}
