import React, { useContext } from 'react'

import { GlobalStateContext } from '../../providers/GlobalStateProvider'
import {
  NavigationContainer, StyledBurger, TitleContainer, TopBar,
} from './Header.styles'
import logo from '../../media/ubs-logo.jpeg'

export const Header: React.FC = () => {
  const {
    pageTitle, userAccess, slideoutOpen, setSlideoutOpen, userInfo,
  } = useContext(GlobalStateContext)

  return (
    <>
      <TopBar>
        <NavigationContainer>
          <StyledBurger onClick={() => setSlideoutOpen(!slideoutOpen)}>
            <div />
            <div />
            <div />
          </StyledBurger>
          {userInfo.name && (
          <div>
            Здравей,
            {' '}
            {userInfo.name}
          </div>
          )}
        </NavigationContainer>
      </TopBar>
      {userAccess !== 0 && (
      <TitleContainer>
        <div><img src={logo} alt="" /></div>
        <div>
          <strong>UBS Helpdesk v0.1</strong>
          <h1>{pageTitle}</h1>
        </div>
      </TitleContainer>
      )}
    </>
  )
}
