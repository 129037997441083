import React, { useContext, useEffect, useState } from 'react'
import * as ls from 'local-storage'
import { GlobalStateContext } from '../providers/GlobalStateProvider'
import { API } from '../providers/API'
import { Machine } from '../components/Machine/Machine'
import { MachineData } from '../components/Machine/Machine.types'
import { MachineFiltersInStorage } from '../components/MachineFilters/MachineFiltersInStorage'

export const MachinesInStorage = () => {
  const [machines, setMachines] = useState([])
  const { setPageTitle } = useContext(GlobalStateContext)
  setPageTitle('Машини в склад')

  useEffect(() => {
    API.get('/storage/listAllStorage', { headers: { Authorization: `Bearer ${ls.get('loginCredentials')}` } }).then((response) => {
      setMachines(response.data)
    })
  }, [])

  return (
    <>
      <MachineFiltersInStorage setMachinesFunction={setMachines} />
      {machines.map((item: MachineData) => (
        <Machine key={item.id} data={item} />
      ))}
    </>
  )
}
