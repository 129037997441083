import React, { useContext } from 'react'
import Button from '@mui/material/Button'
import { GlobalStateContext } from '../../providers/GlobalStateProvider'
import { theme } from '../../theme/theme'

export const ApprovalBoxToggle = () => {
  const { approvalBoxOpen, setApprovalBoxOpen } = useContext(GlobalStateContext)

  return (
    <Button
      variant="contained"
      type="button"
      color="primary"
      fullWidth
      onClick={() => {
        setApprovalBoxOpen(!approvalBoxOpen)
      }}
      style={{ marginBottom: theme.space.lg }}
    >
      {approvalBoxOpen ? 'Затвори' : 'Отвори'}
      {' '}
      форма за одобрение
      <span style={{ display: 'inline-block', marginLeft: '10px', transform: `rotate(${approvalBoxOpen ? '0deg' : '180deg'})` }}>&#9013;</span>
    </Button>
  )
}
