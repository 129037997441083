import React from 'react'
import { Route, Redirect } from 'react-router-dom'

import * as ls from 'local-storage'

interface ProtectedRouteProps {
  component: any
  accessLevel: number
  path: string
}

export const ProtectedRoute:React.FC<ProtectedRouteProps> = ({
  component: Component,
  accessLevel,
  path,
}) => {
  const userAccess:string = ls.get('userAccess')

  return (
    <Route
      exact
      path={path}
      render={(props) => (accessLevel >= parseInt(userAccess) ? (
        <Component {...props} />
      ) : (
        <Redirect to="/" />
      ))}
    />
  )
}
