import React from 'react'
import { useField, FieldHookConfig } from 'formik'
import TextField from '@mui/material/TextField'

type CustomProps = {
    label : string
    multiline?: boolean
  } & typeof defaultProps;

const defaultProps = {
  multiline: false,
}

// eslint-disable-next-line max-len
export const InputField = (props: CustomProps & FieldHookConfig<string>) => {
  const [field, meta] = useField(props)
  const {
    placeholder, type, label, multiline,
  } = props
  const errorText = meta.error && meta.touched ? meta.error : ''

  return (
    <TextField
      {...field}
      placeholder={placeholder}
      type={type}
      label={label}
      variant="outlined"
      fullWidth
      helperText={errorText}
      error={!!errorText}
      multiline={multiline}
    />
  )
}

InputField.defaultProps = defaultProps
