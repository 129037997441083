import styled, { css } from 'styled-components'
import { theme } from '../../theme/theme'

export const MachineHolder = styled.div<{waitingForApproval: boolean}>(
  ({ waitingForApproval }) => css`
  background: ${theme.colors.white};
  border-radius: 5px;
  margin: 0 0 ${theme.space.md};
  padding: ${theme.space.sm}; 
  background: ${waitingForApproval ? '#cefed2' : theme.colors.white};
    `,
)

export const RadioGroupHolder = styled.fieldset`
  border: 1px solid ${theme.colors.blueDark};
  padding: ${theme.space.sm} ${theme.space.md};
  margin-bottom: ${theme.space.md};
  
  & span {
    font-size: 14px !important;
  }
`

export const MachineDataList = styled.div`
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: ${theme.space.md};

  & li {
    margin-right: ${theme.space.md};
    padding-right: ${theme.space.md};
    border-right: 1px solid ${theme.colors.blueDark};
    font-size: 14px;

    &:last-child {
      border: none;
    }

    & span {
      font-weight: bold;
      margin-right: ${theme.space.sm};
    }
  }
`
