import React, { useContext } from 'react'
import { Button } from '@material-ui/core'
import { Formik } from 'formik'
import * as Yup from 'yup'

import * as ls from 'local-storage'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { GlobalStateContext } from '../providers/GlobalStateProvider'
import { API } from '../providers/API'
import { InputField } from '../components/FormElements/InputField'
import { SignatureComponent } from '../components/SignaturePad/SignaturePad'

const ValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email()
    .required('Моля въведете email'),
  password: Yup.string().required('Моля въведете паролата си'),
})

export const ReferencePage = () => {
  const { setPageTitle } = useContext(GlobalStateContext)
  setPageTitle('Reference')

  return (
    <>
      <div>Вход</div>
      <div />
      <SignatureComponent setSignatureDataFn={undefined} />
      <Formik
        initialValues={{ email: 'qqxxqqq', password: '', gender1: 'female' }}
        validationSchema={ValidationSchema}
        enableReinitialize
        onSubmit={(values) => {
          API.get('/sanctum/csrf-cookie').then(() => {
            API.post('/login',
              JSON.stringify(values)).then((response) => {
              if (response.data.token) {
                ls.set('loginCredentials', response.data.token)
                ls.set('userAccess', 0)
              }
            })
          })
        }}
      >
        {({ handleSubmit, values, setFieldValue }) => (
          <form onSubmit={handleSubmit}>
            <InputField label="Email" name="email" id="email" type="text" />
            <InputField label="Парола" name="password" id="password" type="password" />
            <RadioGroup
              aria-label="gender"
              name="gender1"
              value={values.gender1}
              onChange={(event) => {
                setFieldValue('gender1', event.currentTarget.value)
              }}
            >
              <FormControlLabel value="female" control={<Radio />} label="Female" />
              <FormControlLabel value="male" control={<Radio />} label="Male" />
              <FormControlLabel value="other" control={<Radio />} label="Other" />
              <FormControlLabel value="disabled" disabled control={<Radio />} label="(Disabled option)" />
            </RadioGroup>
            <Button
              variant="contained"
              type="submit"
              color="primary"
              fullWidth
            >
              Вход
            </Button>

          </form>
        )}
      </Formik>
    </>
  )
}
